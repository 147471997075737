








import { defineComponent } from "@vue/composition-api"

import MissionCard from "@/components/GroupTeams/Common/Games/GameCardParts/MissionCard.vue"
import useJeopardAiCategories from "../use/useJeopardAiCategories"
import JeopardAiCategoriesList from "./JeopardAiCategoriesList.vue"
import JeopardAiCategoriesForm from "./JeopardAiCategoriesForm.vue"

export default defineComponent({
  name: "JeopardAiCategoriesMobile",
  components: {
    MissionCard,
    JeopardAiCategoriesList,
    JeopardAiCategoriesForm
  },
  setup() {
    const { items, isViewerHostLike } = useJeopardAiCategories()

    return {
      items,
      isViewerHostLike
    }
  }
})
